<script setup lang="ts">
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { getLocaleName } from '@@/bits/locale'
import { reload } from '@@/bits/location'
import OzDropdownButton, {
  OzDropdownButtonColorScheme,
  OzDropdownButtonSizePreset,
} from '@@/library/v4/components/OzDropdownButton.vue'
import { getAnchorFromElement } from '@@/library/v4/components/OzPopoverModal.vue'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { PopoverAnchor, VueCssClass } from '@@/types'
import { useLocale } from '@@/vuecomposables/locale'
import { uniqueId } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, ref } from 'vue'

const LocaleMenu = defineAsyncComponent(() => import('@@/vuecomponents/LocaleMenu.vue'))

withDefaults(
  defineProps<{
    buttonColorScheme?: OzDropdownButtonColorScheme
    darkMode?: 'auto' | boolean
    shouldBoldDropdownButtonText?: boolean
    /**
     * Customizes the gap color between the button and the focus ring.
     */
    dropdownButtonRingOffsetColorClasses?: VueCssClass
    /**
     * Customizes the text color on the button.
     */
    dropdownButtonTextColorClasses?: VueCssClass
  }>(),
  {
    buttonColorScheme: OzDropdownButtonColorScheme.SecondaryClearText,
    darkMode: 'auto',
    shouldBoldDropdownButtonText: false,
    dropdownButtonRingOffsetColorClasses: undefined,
    dropdownButtonTextColorClasses: undefined,
  },
)

const windowSizeStore = useWindowSizeStore()
const { isSmallerThanTabletPortrait, windowHeight } = storeToRefs(windowSizeStore)

const { changeLocale } = useLocale()

const currentLocale = ref(getHyphenatedCurrentLocale())
const isSelectorOpen = ref(false)
const menuAnchor = ref({} as Partial<PopoverAnchor>)
const localeSelector = ref<InstanceType<typeof OzDropdownButton>>()
const currentLocaleName = computed(() => getLocaleName(currentLocale.value))

function updateMenuAnchor(): void {
  const el = localeSelector?.value?.$el
  if (!el) return
  menuAnchor.value = getAnchorFromElement(el, { top: -4, left: -4 })
}

function openMenu(): void {
  updateMenuAnchor()
  isSelectorOpen.value = true
}

function closeMenu(): void {
  isSelectorOpen.value = false
}

async function handleChangeLocale(lang: string): Promise<void> {
  isSelectorOpen.value = false

  if (currentLocale.value === lang) return

  currentLocale.value = lang
  await changeLocale(lang)

  reload()
}

// Wait for the page fully rendered before (re)calculating the menu anchor
const onPageLoad = () => {
  updateMenuAnchor()
  window.removeEventListener('load', onPageLoad)
}
window.addEventListener('load', onPageLoad)

const ariaDescribedbyId = uniqueId('locale-selector-button-description-')
</script>

<template>
  <div>
    <OzDropdownButton
      ref="localeSelector"
      :aria-describedby="ariaDescribedbyId"
      :color-scheme="buttonColorScheme"
      :size-preset="OzDropdownButtonSizePreset.H32px"
      :text="currentLocaleName"
      :dark-mode="darkMode"
      :should-bold-text="shouldBoldDropdownButtonText"
      :ring-offset-color-classes="dropdownButtonRingOffsetColorClasses"
      :text-color-classes="dropdownButtonTextColorClasses"
      data-testid="localeMenuButton"
      @click="openMenu"
      @keypress.stop.space="openMenu"
    />
    <p :id="ariaDescribedbyId" class="sr-only">{{ __('Selecting a different language will reload the page.') }}</p>

    <LocaleMenu
      v-if="isSelectorOpen"
      :anchor="menuAnchor"
      :current-locale="currentLocale"
      :is-phone="isSmallerThanTabletPortrait"
      :box-classes="['h-100']"
      :window-height="windowHeight"
      @back="closeMenu"
      @cancel="closeMenu"
      @change="handleChangeLocale"
      @anchor-update="updateMenuAnchor"
    />
  </div>
</template>
