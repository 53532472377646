// @file Composable to encapsulate locale logic
import getCsrfToken from '@@/bits/csrf_token'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { fetchJson, HTTPMethod } from '@padlet/fetch'

export const useLocale = (): { changeLocale: (locale: string) => Promise<void> } => {
  const changeLocale = async (locale: string): Promise<void> => {
    await fetchJson('/session', {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ lang: locale }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    })
  }

  return { changeLocale }
}
